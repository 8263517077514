<template>
  <!-- <v-card class="mx-auto pa-3 mb-3" max-width="800" outlined>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="text-h6">Game Link</div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div class="pr-2">
              <v-btn
                width="100px"
                rounded
                color="primary"
                :href="gameUrl"
                target="_blank"
                dark
              >
                Play
              </v-btn>
            </div>
            <div>
              <v-btn width="100px" rounded color="primary" dark> Share </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="pt-2">
        <v-text-field v-model="gameUrl" outlined dense></v-text-field>
      </div>
    </v-card> -->
  <v-card color="grey lighten-3" class="mx-auto pa-3 mb-3" max-width="800" outlined>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-h6">
          <!-- <span v-if="$_getLocale() == 'zh'">{{$t('model.name.'+type)}}{{$t('model.prop.code')}}</span>
          <span class="text-capitalize" v-else-if="$_getLocale() == 'ms'">{{$t('model.prop.code')}} {{$t('model.name.'+type)}}</span>
          <span class="text-capitalize" v-else>{{$t('model.name.'+type)}} {{$t('model.prop.code')}}</span> -->
          <span class="text-capitalize">CODE </span>
          <v-chip class="ml-2" color="yellow">{{ group.code }}</v-chip>
        </div>
        <!-- <a target="blank" :href="gameUrl" class="text-decoration-none"
          >How to enter the game?</a
        > -->
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center justify-sm-end">
          <div class="pr-2">
            <v-btn
              width="100px"
              rounded
              color="yellow darken-2"
              :href="gameUrl"
              target="_blank"
            >
              Play
            </v-btn>
          </div>
          <div>
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="100px"
                  rounded
                  color="green"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Share
                </v-btn>
              </template>

              <v-list width="180px">
                <v-list-item v-if="group">
                  <v-list-item-action class="text-center">
                    <ShareNetwork
                      class="text-decoration-none"
                      network="whatsapp"
                      :title="message1+group.code+message2"
                      :url="gameUrl"
                    >
                      <v-btn width="150px" rounded color="green" dark>
                        <v-icon left dark> mdi-whatsapp </v-icon>
                        Whatsapp
                      </v-btn>
                    </ShareNetwork>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action
                    ><v-btn
                      width="150px"
                      :disabled="copied"
                      @click="copy"
                      rounded
                      color="primary"
                    >
                      <v-icon left dark> mdi-content-copy </v-icon>
                      <span v-if="copied">Copied</span>
                      <span v-else>Copy</span>
                    </v-btn></v-list-item-action
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: ["type","id"],
  data: () => ({
    copied: false,
    gameUrl: null,
    whatsappUrl: null,
    group: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    message1:null,
    message2:null,
  }),
  created() {
    this.message1 = {
      "en":"You're invited to join Hamochi Maths, the most exciting school holiday Maths event ever. Code: ",
      "zh":"您被邀请参加 Hamochi Maths，最有趣的学校假期线上数学活动！Code：",
      "ms":"Kamu telah dijemputkan untuk menghadiri Hamochi Maths, aktiviti Matematik atas talian yang menarik! Kod: ",
    }[this.$_getLocale()];
    this.message2 = {
      "en":". Join now: ",
      "zh":"。马上开始：",
      "ms":". Sertai sekarang: ",
    }[this.$_getLocale()];
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/" +
      this.type +
      "/" +
      this.id +
      "/group/getCode";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.group = resp;
      this.gameUrl = `${process.env.VUE_APP_URL_WEB_GAME}/${this.$_getLocale()}/access?code=${this.group.code}`;
    };
    // if (this.type != "institution") {
    //   this.group = this.$_.find(this.auth.Groups, { type: this.type });
    //   this.gameUrl = `${process.env.VUE_APP_URL_WEB_GAME}/en/entrance?code=${this.group.code}`;
    // } else {
    //   this.$api.fetch(this.api);
    // }
   
    this.$api.fetch(this.api);

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.whatsappUrl = "https://api.whatsapp.com/send?text=" + this.gameUrl;
    } else {
      // false for not mobile device
      this.whatsappUrl =
        "https://web.whatsapp.com/send?l=en&text=" + this.gameUrl;
    }
  },
  mounted() {
    //
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.message1+this.group.code+this.message2+'\n'+this.gameUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
  },
};
</script>